export class Boton {
    texto: string;
    color: string;
    hoverColor: string;
    hoverBackColor: string
    link:string
  
    constructor(boton:any) {
      this.texto = boton.texto || "";
      this.color = boton.color || "";
      this.hoverColor = boton.hoverColor || "";
      this.hoverBackColor = boton.hoverBackColor || "";
      this.link = boton.link || "";
    }
  }
  