import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../services/settings.service';
import { I18nService } from '../../../services/i18n.service';
import { PagesService } from '../../../services/collections/pages.service';
import { AlertService } from '../../../services/alert.service';
import { NavigationService } from '../../../services/navigation.service';
import { Info, Page } from '../../../models/collections/page.model';
import { Boton } from '../../../models/collections/boton.model';
import { SectionCTA } from '../../../models/collections/section-cta.model';
import { SectionFooter } from '../../../models/collections/section-footer.model';
import { SectionHome } from '../../../models/collections/section-home.model';
import { SectionNewsletter } from '../../../models/collections/section-newsletter.model';
import { SectionPieVideo } from '../../../models/collections/section-pie_video.model';
import { SectionProductos } from '../../../models/collections/section-productos.model';
import { SectionTestimonios } from '../../../models/collections/section-testimonio.model';
import { SectionVideo } from '../../../models/collections/section-video.model';

@Component({
  selector: 'fa-pages-add',
  templateUrl: './pages-add.component.html',
  styleUrls: ['./pages-add.component.css']
})
export class PagesAddComponent implements OnInit {

  page: Page;
  sections: any[];

  constructor(
    private settings: SettingsService,
    private i18n: I18nService,
    private pagesService: PagesService,
    private alert: AlertService,
    private navigation: NavigationService
  ) { }

  ngOnInit() {
    this.sections = this.pagesService.getSectionForm();

    this.page = new Page(
      "",
      "",
      "",
      new Info({}),
      "",
      "",
      "",
      "",
      null,
      null,
      null,
      "",
      "",
      "",
      new SectionHome({}),
      new SectionProductos({}),
      new SectionCTA({}),
      new SectionVideo({}),
      new SectionPieVideo({}),
      new SectionTestimonios({}),
      new SectionNewsletter({}),
      new SectionFooter({})
    );
    console.log(this.page)
  }

  addPage(event: Event) {
    const addButon = event.target as any;
    const startLoading = () => {
      addButon.isLoading = true;
    };
    const stopLoading = () => {
      addButon.isLoading = false;
    };
    startLoading();
    // Add page
    this.pagesService.isRouteDuplicated(this.page.ruta).then((isDup) => {
      if (isDup) {
        this.alert.warning(this.i18n.get('PostSlugAlreadyExists'), false, 5000);
        stopLoading();
      } else {
        this.pagesService.add(this.page).then(() => {
          this.alert.success(this.i18n.get('PageAdded'), false, 5000, true);
          this.navigation.redirectTo('pages', 'list');
        }).catch((error: Error) => {
          this.alert.error(error.message);
        }).finally(() => {
          stopLoading();
        });
      }
    })
  }

}
