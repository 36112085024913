import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ImagePickerComponent } from '../../image-picker/image-picker.component';
import { Boton } from '../../models/collections/boton.model';
import { Page } from '../../models/collections/page.model';
import { Producto } from '../../models/collections/producto.model';
import { Testimonio } from '../../models/collections/testimonio.model';
import { PagesService } from '../../services/collections/pages.service';

@Component({
  selector: 'fa-form-page',
  templateUrl: './form-page.component.html',
  styleUrls: ['./form-page.component.css']
})
export class FormPageComponent implements OnInit {
  @Input() page: Page;
  sections: any[];
  @ViewChild('imageModal', { static: false }) imageModal: ElementRef;

  bsModalRef?: BsModalRef;
  constructor(private pages:PagesService, private modalService: BsModalService) {}
 
  openModalWithComponent(obj:any, prop:string) {
    this.bsModalRef = this.modalService.show(ImagePickerComponent, { class: 'modal-lg', initialState:{formRef:obj, prop:prop} } );
  }

  ngOnInit() {
    this.sections = this.pages.getSectionForm();
  }


  addProducto(section: string, property: string) {
    if (!this.page[section][property]) {
      this.page[section][property] = [];
    }
    this.page[section][property].push(new Producto("","","","",new Boton(this.page[section].boton)));
  }
 
  remove(section: string, property: string, i:number) {
    this.page[section][property].splice(i,1)
  }
 
  addTestimonio(section: string, property: string) {
    if (!this.page[section][property]) {
      this.page[section][property] = [];
    }
    this.page[section][property].push(new Testimonio("","","",""));
  }

  // async uploadImage() {
  //   const filePath = `images/${this.image.name}`;
  //   const fileRef = this.storage.ref(filePath);
  //   const task = fileRef.put(this.selectedFile);
 
  //   task.then(() => {
  //     fileRef.getDownloadURL().subscribe(url => {
  //       // Guarde la URL en Firestore
  //     });
  //   });
  // }

}
