import { Boton } from './boton.model';

export class SectionNewsletter {
  titulo: string;
  input: string;
  boton: Boton;
  enable: boolean
  backColor:string;

  constructor(news:any) {
    this.titulo = news.titulo;
    this.input = news.input;
    this.boton = news.boton ? new Boton(news.boton) : new Boton({});;
    this.enable = news.enable || false;
    this.backColor = news.backColor || ""

  }
}
