import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PagesService } from '../services/collections/pages.service';
import { StorageService } from '../services/storage.service';

@Component({
  selector: 'fa-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.css']
})
export class ImagePickerComponent implements OnInit {

  @Output() close = new EventEmitter<void>();
  images = [];
  formRef: any;
  prop:string;

  constructor(private storage: StorageService, private pages: PagesService, public bsModalRef: BsModalRef) {}

  ngOnInit() {
    this.storage.getStorage().child('pages').listAll().then(result => {
      result.items.forEach(async ref => {
        const url = await ref.getDownloadURL();
        this.images.push({ url, path: ref.fullPath });
      });
    });
  }

  selectImage(image) {
    // aquí puedes hacer un update en Firestore con la URL de la imagen seleccionada
    this.formRef[this.prop] = image.url;
    this.bsModalRef.hide();
  }

  onImageChange(event: Event) {
    let img = (event.target as HTMLInputElement).files[0];
    this.pages.uploadImage(img).then( snap=> {
      snap.ref.getDownloadURL().then((url) => {
        this.images.push({ url, path: snap.metadata.fullPath });
      })
    });
  }

  deleteImage(image, i) {
    this.pages.deleteImage(image.path).then(() => {
      this.images.splice(i,1);
    })
  }

}
