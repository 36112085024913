import { Observable } from 'rxjs';
import { SectionHome } from './section-home.model';
import { SectionProductos } from './section-productos.model';
import { SectionCTA } from './section-cta.model';
import { SectionNewsletter } from './section-newsletter.model';
import { SectionPieVideo } from './section-pie_video.model';
import { SectionTestimonios } from './section-testimonio.model';
import { SectionVideo } from './section-video.model';
import { SectionFooter } from './section-footer.model';

export class Info {
  ubicacion:string;
  telefono: string;
  email: string;
  whatsapp: string;

  constructor(info:any) {
    this.ubicacion = info.ubicacion || "";
    this.telefono = info.telefono || "";
    this.email = info.email || "";
    this.whatsapp = info.whatsapp || "";
  }
}

export class Redes {
  color: string
  hover: string
  facebook: string
  twitter: string
  linkedin: string
  instagram: string
  youtube: string
  tiktok: string

  constructor(redes:any) {
    this.color = redes.color || "white";
    this.hover = redes.hover || "white";
    this.facebook = redes.facebook || "";
    this.twitter = redes.twitter || "";
    this.linkedin = redes.linkedin || "";
    this.instagram = redes.instagram || "";
    this.youtube = redes.youtube || "";
    this.tiktok = redes.tiktok || "";
  }
}

export class Page {
  id?: string;
  title: string;
  ruta: string;
  info:Info;
  titleColor: string;
  backColor: string;
  fontPrimaryUrl: string;
  fontSecondaryUrl: string;
  redes: Redes;
  createdAt?: number;
  updatedAt?: number;
  createdBy?: string;
  author?: string|Observable<string>;
  updatedBy?: string;
  sectionHome?: SectionHome;
  sectionProductos?: SectionProductos;
  sectionCTA?: SectionCTA;
  sectionVideo?: SectionVideo;
  sectionPieVideo?: SectionPieVideo;
  sectionTestimonios?: SectionTestimonios;
  sectionNewsletter?: SectionNewsletter;
  sectionFooter?: SectionFooter;
  enable: boolean;

  constructor(id:string, title:string, ruta:string, info:Info, titleColor:string, backColor:string, fontPrimaryUrl:string, fontSecondatyUrl:string, redes:Redes, createdAt: number, updatedAt: number, createdBy:string, author:string|Observable<string>, updatedBy:string, sectionHome?: SectionHome, sectionProductos?: SectionProductos, sectionCTA?: SectionCTA, sectionVideo?: SectionVideo, sectionPieVideo?: SectionPieVideo, sectionTestimonios?: SectionTestimonios, sectionNewsletter?: SectionNewsletter, sectionFooter?: SectionFooter, enable?:boolean) {
    this.id=id;
    this.title=title || "";
    this.ruta=ruta || ""
    this.info = info ? new Info(info) : new Info({});
    this.titleColor=titleColor || "white";
    this.backColor=backColor || "#0f132d";
    this.fontPrimaryUrl = fontPrimaryUrl;
    this.fontSecondaryUrl = fontSecondatyUrl;
    this.redes = redes ? new Redes(redes) : new Redes({});
    this.createdAt=createdAt;
    this.updatedAt=updatedAt;
    this.createdBy=createdBy;
    this.author=author;
    this.updatedBy=updatedBy;
    this.sectionHome = new SectionHome(sectionHome);
    this.sectionProductos = new SectionProductos(sectionProductos);
    this.sectionCTA = new SectionCTA(sectionCTA);
    this.sectionVideo = new SectionVideo(sectionVideo);
    this.sectionPieVideo = new SectionPieVideo(sectionPieVideo);
    this.sectionTestimonios = new SectionTestimonios(sectionTestimonios);
    this.sectionNewsletter = new SectionNewsletter(sectionNewsletter);
    this.sectionFooter = new SectionFooter(sectionFooter);
    this.enable = enable || true;
  }
}
