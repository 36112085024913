export class SectionVideo {
    titulo: string;
    videoUrl: string;
    previewImage: string;
    enable: boolean
    backColor:string;
  
    constructor(video:any) {
      this.titulo = video.titulo;
      this.videoUrl = video.videoUrl;
      this.enable = video.enable || false;
      this.previewImage = video.previewImage || "";
      this.backColor = video.backColor || ""
    }
  }
  