import { Injectable } from '@angular/core';
import { DatabaseService } from '../database.service';
import { UsersService } from './users.service';
import { Page } from '../../models/collections/page.model';
import { guid, isFile, now } from '../../helpers/functions.helper';
import { mergeMap, take } from 'rxjs/operators';
import { SettingsService } from '../settings.service';
import { Observable, of } from 'rxjs';
import { QueryFn } from '@angular/fire/firestore';
import { StorageService } from '../storage.service';

@Injectable()
export class PagesService {

  constructor(
    protected db: DatabaseService,
    private settings: SettingsService,
    private storage: StorageService,
    private users: UsersService
  ) {
  }

  add(page: Page) {
    page.createdAt = now();
    page.createdBy = this.db.currentUser.id
    page.id = this.db.createId()
    var pageObj = this.mapperPageObject(page)
    return new Promise((resolve, reject) => {
      this.db.addDocument('pages', pageObj, pageObj.id).then((doc: any) => {
        resolve(doc);
      }).catch((error: Error) => {
        reject(error);
      });
    });
  }

  get(id: string) {
    return this.db.getDocument('pages', id).pipe(mergeMap(async (page: Page) => {
      page.id = id;
      return page;
    }));
  }

  private pipePages(pagesObservable: Observable<Page[]>) {
    return pagesObservable.pipe(mergeMap(async (pages: Page[]) => {
      //pages.forEach((page: Page) => { // forEach loop doesn't seems to work well with async/await
      for (let page of pages) {
        // console.log(page);
        // console.log(page.translations);
        page.author = page.createdBy ? this.users.getFullName(page.createdBy) : of(null);
      }
      //});
      return pages;
    }));
  }

  getAll() {
    return this.pipePages(this.db.getCollection('pages'));
  }

  getWhere(field: string, operator: firebase.firestore.WhereFilterOp, value: string, applyPipe: boolean = false) {
    return this.getWhereFn(ref => ref.where(field, operator, value), applyPipe);
  }

  getWhereFn(queryFn: QueryFn, applyPipe: boolean = false) {
    const pagesObservable = this.db.getCollection('pages', queryFn);
    return applyPipe ? this.pipePages(pagesObservable) : pagesObservable;
  }

  isRouteDuplicated(ruta: string, id?: string): Promise<boolean> {
   return new Promise((resolve, reject) => {
     this.getWhereFn(ref => ref.where('ruta', '==', ruta)).pipe(take(1)).toPromise().then((pages: any[]) => {
       //console.log(posts, posts[0]['id']);
       resolve(pages && pages.length && (!id || (pages[0]['id'] as any) !== id));
     }).catch((error: Error) => {
       reject(error);
     });
   });
 }

  edit(page: Page) {
    page.updatedAt = now(),
    page.updatedBy = this.db.currentUser.id
    var pageObj = this.mapperPageObject(page)
    return new Promise((resolve, reject) => {
      this.db.setDocument('pages', pageObj.id, pageObj).then(() => {
        resolve(true)
      }).catch((error: Error) => {
        reject(error);
      });
    });
  }

  delete(id: string) {
    return new Promise((resolve, reject) => {
        this.db.deleteDocument('pages', id).then(() => {
          resolve(true);
        }).catch((error: Error) => {
          reject(error);
        });

    });
  }

  uploadImage(imageFile: File): Promise<firebase.storage.UploadTaskSnapshot> {
    return new Promise((resolve, reject) => {
      if (imageFile && isFile(imageFile)) {
        const imageName = guid() + '.' + imageFile.name.split('.').pop();
        const imagePath = `pages/${imageName}`;
        this.storage.upload(imagePath, imageFile).then((snap) => {
          resolve(snap)
        }).catch((rej) => reject(rej));
      } else {
        reject();
      }
    });
  }

  deleteImage(imagePath: string) {
    return new Promise((resolve, reject) => {
      // console.log(imagePath);
      if (imagePath) {
        this.storage.delete(imagePath).toPromise().then(() => {
          resolve();
        }).catch((error: Error) => {
          reject(error);
        });
      } else {
        resolve();
      }
    });
  }

  countAll() {
    return this.db.getDocumentsCount('pages');
  }

  countWhereFn(queryFn: QueryFn) {
    return this.db.getDocumentsCount('pages', queryFn);
  }

  countWhere(field: string, operator: firebase.firestore.WhereFilterOp, value: string) {
    return this.countWhereFn(ref => ref.where(field, operator, value));
  }

  mapperPageObject (page:Page): any {
   if (page.sectionProductos.productos && page.sectionProductos.productos.length > 0) {
      page.sectionProductos.productos.forEach((prod) => {
         prod.boton.color = page.sectionProductos.boton.color;
         prod.boton.hoverBackColor = page.sectionProductos.boton.hoverBackColor;
         prod.boton.hoverColor = page.sectionProductos.boton.hoverColor;
         prod.boton.texto = page.sectionProductos.boton.texto;
      })
   }
   const pageData = JSON.parse(JSON.stringify(page));
   return pageData;
  }

  getSectionForm() {
    return [
      {
         "name":"Section Home",
         "property":"sectionHome",
         "hasInnerProperties":true,
         "innerProperties":[
            {
               "name":"Titulo",
               "property":"titulo"
            },
            {
               "name":"Bajada",
               "property":"bajada"
            },
            {
               "name":"Color de Fondo",
               "property":"backColor",
               "picker": true
            },
            {
               "name":"Color",
               "property":"color",
               "picker":true
            },
            {
               "name":"Img",
               "property":"img",
               "isImg": true
            },
            {
               "name":"Boton",
               "property":"boton",
               "hasInnerProperties":true,
               "innerProperties":[
                  {
                     "name":"Texto",
                     "property":"texto"
                  },
                  {
                     "name":"Link",
                     "property":"link"
                  },
                  {
                     "name":"Color",
                     "property":"color",
                     "picker":true
                  },
                  {
                     "name":"HoverColor",
                     "property":"hoverColor",
                     "picker":true
                  },
                  {
                    "name":"HoverBackColor",
                    "property":"hoverBackColor",
                    "picker":true
                 }
               ]
            }
         ]
      },
      {
         "name":"Section Productos",
         "property":"sectionProductos",
         "hasInnerProperties":true,
         "innerProperties":[
            {
               "name":"Titulo",
               "property":"titulo"
            },
            {
               "name":"Color Textos",
               "property":"textColor",
               "picker": true
            },
            {
               "name":"Color de Fondo",
               "property":"backColor",
               "picker": true
            },
            {
               "name":"Boton",
               "property":"boton",
               "hasInnerProperties":true,
               "innerProperties":[
                  {
                     "name":"Texto",
                     "property":"texto"
                  },
                  {
                     "name":"Color",
                     "property":"color",
                     "picker":true
                  },
                  {
                     "name":"HoverColor",
                     "property":"hoverColor",
                     "picker":true
                  },
                  {
                    "name":"HoverBackColor",
                    "property":"hoverBackColor",
                    "picker":true
                 }
               ]
            },
            {
             "name":"Productos",
             "property":"productos",
             "hasInnerProperties":true,
             "innerProperties":[
                {
                   "name":"Titulo",
                   "property":"titulo"
                },
                {
                   "name":"Foto",
                   "property":"foto",
                   "isImg": true
                },
                {
                   "name":"Precio",
                   "property":"precio"
                },
                {
                   "name":"Moneda",
                   "property":"moneda"
                },
                {
                  "name":"Boton",
                  "property":"boton",
                  "hasInnerProperties":true,
                  "innerProperties":[
                     {
                      "name":"Link",
                      "property":"link"
                     }
                  ]
               }
             ]
            }
         ]
      },
      {
         "name":"Section CTA",
         "property":"sectionCTA",
         "hasInnerProperties":true,
         "innerProperties":[
            {
               "name":"Titulo",
               "property":"titulo"
            },
            {
               "name":"Descripcion",
               "property":"descripcion"
            },
            {
               "name":"CTA",
               "property":"cta"
            },
            {
               "name":"Color de Fondo",
               "property":"backColor",
               "picker": true
            },
            {
               "name":"Color",
               "property":"color",
               "picker":true
            },
            {
               "name":"Foto",
               "property":"foto",
               "isImg": true
            },
            {
               "name":"Boton",
               "property":"boton",
               "hasInnerProperties":true,
               "innerProperties":[
                  {
                     "name":"Texto",
                     "property":"texto"
                  },
                  {
                     "name":"Color",
                     "property":"color",
                     "picker":true
                  },
                  {
                     "name":"HoverColor",
                     "property":"hoverColor",
                     "picker":true
                  },
                  {
                    "name":"HoverBackColor",
                    "property":"hoverBackColor",
                    "picker":true
                 }
               ]
            }
         ]
      },
      {
         "name":"Section Video",
         "property":"sectionVideo",
         "hasInnerProperties":true,
         "innerProperties":[
            {
               "name":"Titulo",
               "property":"titulo"
            },
            {
               "name":"Color de Fondo",
               "property":"backColor",
               "picker": true
            },
            {
               "name":"Preview Imagen",
               "property":"previewImage",
               "isImg":true
            },
            {
               "name":"VideoUrl",
               "property":"videoUrl"
            }
         ]
      },
      {
         "name":"Section PieVideo",
         "property":"sectionPieVideo",
         "hasInnerProperties":true,
         "innerProperties":[
            {
               "name":"Titulo",
               "property":"titulo"
            },
            {
               "name":"Descripcion",
               "property":"descripcion"
            },
            {
               "name":"Color de Fondo",
               "property":"backColor",
               "picker": true
            },
            {
               "name":"Img",
               "property":"img",
               "isImg": true
            }
         ]
      },
      {
         "name":"Section Testimonios",
         "property":"sectionTestimonios",
         "hasInnerProperties":true,
         "innerProperties":[
            {
               "name":"Titulo",
               "property":"titulo"
            },
            {
               "name":"Color de Fondo",
               "property":"backColor",
               "picker": true
            },
            {
               "name":"Color Principal",
               "property":"colorPrimary",
               "picker":true
            },
            {
               "name":"Color Secundario",
               "property":"colorSecondary",
               "picker":true
            },
            {
               "name":"Lista",
               "property":"lista",
               "hasInnerProperties":true,
               "innerProperties":[
                  {
                     "name":"Foto",
                     "property":"foto",
                     "isImg": true
                  },
                  {
                     "name":"Nombre",
                     "property":"nombre"
                  },
                  {
                     "name":"Cargo",
                     "property":"cargo"
                  },
                  {
                     "name":"Descripcion",
                     "property":"descripcion"
                  }
               ]
            }
         ]
      },
      {
         "name":"Section Footer",
         "property":"sectionFooter",
         "hasInnerProperties":true,
         "innerProperties":[
            {
               "name":"Titulo",
               "property":"titulo"
            },
            {
               "name":"Titulo datos de contacto",
               "property":"contactTitle"
            },
            {
               "name":"Titulo newsletter",
               "property":"newsTitle"
            },
            {
               "name":"PlaceHolder input Newsletter",
               "property":"placeholderInptNews"
            },
            {
               "name":"Texto Boton Newslettere",
               "property":"newsTextButton"
            },
            {
               "name":"Copyright",
               "property":"copyright"
            },
            {
               "name":"Color de Fondo",
               "property":"backColor",
               "picker": true
            },
            {
               "name":"Color",
               "property":"color",
               "picker":true
            },
         ]
      }
   ];
  }

}
