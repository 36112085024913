// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCPvdNP66mRReIPA5SaUhnVbGsZJBu0lTw",
    authDomain: "rodrysoler-43402.firebaseapp.com",
    projectId: "rodrysoler-43402",
    storageBucket: "rodrysoler-43402.appspot.com",
    messagingSenderId: "253618015372",
    appId: "1:253618015372:web:c2c428b34b2ea378cd3fa9",
    measurementId: "G-FM1XH4FD9J"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
