export class SectionPieVideo {
    titulo: string;
    descripcion: string;
    img: string;
    enable: boolean
    backColor:string;
  
    constructor(pie:any) {
      this.titulo = pie.titulo;
      this.descripcion = pie.descripcion;
      this.img = pie.img;
      this.enable = pie.enable || false;
      this.backColor = pie.backColor || ""
    }
  }
  