import { Boton } from './boton.model';

export class SectionHome {
  titulo: string;
  bajada: string;
  boton: Boton;
  color: string;
  img: string;
  enable: boolean;
  backColor:string;

  constructor(home:any) {
    this.titulo = home.titulo;
    this.bajada = home.bajada;
    this.boton = home.boton ? new Boton(home.boton) : new Boton({});
    this.color = home.color;
    this.img = home.img;
    this.enable = home.enable || false;
    this.backColor = home.backColor || ""
  }
}

