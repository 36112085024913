import { Testimonio } from "./testimonio.model";

export class SectionTestimonios {
    titulo: string;
    colorPrimary: string;
    colorSecondary: string;
    lista: Testimonio[];
    enable: boolean;
    backColor:string;
  
    constructor(testi:any) {
      this.titulo = testi.titulo;
      this.lista = testi.lista;
      this.enable = testi.enable || false;
      this.colorPrimary = testi.colorPrimary;
      this.colorSecondary = testi.colorSecondary
      this.backColor = testi.backColor || ""
    }
  }
  