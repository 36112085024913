import { Boton } from "./boton.model";

export class SectionCTA {
    titulo: string;
    descripcion: string;
    cta: string;
    foto: string;
    enable:boolean;
    boton: Boton;
    color: string;
    backColor:string;
  
    constructor(cta:any) {
      this.titulo = cta.titulo;
      this.descripcion = cta.descripcion;
      this.cta = cta.cta;
      this.foto = cta.foto;
      this.enable = cta.enable || false;
      this.boton = cta.boton ? new Boton(cta.boton) : new Boton({});
      this.color = cta.color;
      this.backColor = cta.backColor || ""
    }
  }
  