import { Component, OnInit, OnDestroy } from '@angular/core';
import { Page } from '../../../models/collections/page.model';
import { I18nService } from '../../../services/i18n.service';
import { PagesService } from '../../../services/collections/pages.service';
import { AlertService } from '../../../services/alert.service';
import { NavigationService } from '../../../services/navigation.service';
import { Subscription, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'fa-pages-edit',
  templateUrl: './pages-edit.component.html',
  styleUrls: ['./pages-edit.component.css']
})
export class PagesEditComponent implements OnInit, OnDestroy {

  title: string;
  sections: any[];
  page: Page;
  isSubmitButtonsDisabled: boolean = false;
  private subscription: Subscription = new Subscription();
  private routeParamsChange: Subject<void> = new Subject<void>();
  

  constructor(
    private i18n: I18nService,
    private pages: PagesService,
    private alert: AlertService,
    public navigation: NavigationService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
   
    this.isSubmitButtonsDisabled = true;
    this.subscription.add(
      this.route.params.subscribe((params: { id: string }) => {
        // console.log(params);
        this.pages.get(params.id).pipe(take(1)).toPromise().then((page: Page) => {
          // console.log(page);
          if (page) {
            this.title = page.title;
            this.page = new Page(page.id, page.title, page.ruta, page.info, page.titleColor, page.backColor, page.fontPrimaryUrl, page.fontSecondaryUrl, page.redes,page.createdAt,page.updatedAt,page.createdBy,page.author,page.updatedBy,page.sectionHome,page.sectionProductos,page.sectionCTA,page.sectionVideo,page.sectionPieVideo,page.sectionTestimonios,page.sectionNewsletter,page.sectionFooter)
            this.routeParamsChange.next();
            this.isSubmitButtonsDisabled = false;
          } else {
            this.navigation.redirectTo('pages', 'list');
          }
        });
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.routeParamsChange.next();
  }

  savePage(event: Event) {
    const target = event.target as any;
    const startLoading = () => {
      target.isLoading = true;
      this.isSubmitButtonsDisabled = true;
    };
    const stopLoading = () => {
      target.isLoading = false;
      this.isSubmitButtonsDisabled = false;
    };
    startLoading();
    this.page.title = this.title;
    this.pages.isRouteDuplicated(this.page.ruta, this.page.id).then((isDup) => {
      if (isDup) {
        this.alert.warning(this.i18n.get('PostSlugAlreadyExists'), false, 5000);
        stopLoading();
      } else {
        this.pages.edit(this.page).then(() => {
          this.alert.success(this.i18n.get('PageSaved'), false, 5000, true);
          this.navigation.redirectTo('pages', 'list');
        }).catch((error: Error) => {
          this.alert.error(error.message);
        }).finally(() => {
          stopLoading();
        });
      }
    })
      
  }

}
