import { Boton } from './boton.model';
import { Producto } from './producto.model';

export class SectionProductos {
  titulo: string;
  productos: Producto[];
  backColor: string;
  textColor: string;
  enable: boolean;
  boton:Boton;

  constructor(products:any) {
    this.titulo = products.titulo;
    this.productos = products.productos;
    this.backColor = products.backColor || "";
    this.textColor = products.textColor || "";
    this.enable = products.enable || false;
    this.boton = products.boton ? new Boton(products.boton) : new Boton({});
  }
}
