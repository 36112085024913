export class SectionFooter {
  titulo: string;
  contactTitle: string;
  newsTitle:string;
  placeholderInptNews:string;
  newsTextButton: string;
  copyright:string;
  enable:boolean;
  color: string;
  backColor:string;

  constructor(footer:any) {
    this.titulo = footer.titulo || "";
    this.enable = footer.enable || false;
    this.contactTitle = footer.contactTitle || "";
    this.newsTitle = footer.newsTitle || "";
    this.placeholderInptNews = footer.placeholderInptNews || "";
    this.newsTextButton = footer.newsTextButton || "";
    this.copyright = footer.copyright || "";
    this.color = footer.color || "";
    this.backColor = footer.backColor || ""
  }
}