export class Testimonio {
    foto: string;
    nombre: string;
    cargo: string;
    descripcion: string;
  
    constructor(foto: string, nombre: string, cargo: string, descripcion: string) {
      this.foto = foto;
      this.nombre = nombre;
      this.cargo = cargo;
      this.descripcion = descripcion;
    }
  }