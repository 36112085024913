export const es = {
    // Login page
    "SignInToYourAccount": "Ingresar a tu cuenta",
    "EmailAddress": "Email",
    "EnterEmail": "Ingresar email",
    "Password": "Contraseña",
    "RememberMe": "Recordarme",
    "SignIn": "Ingresar",
    "ForgotYourPassword": "¿Olvido su Contraseña?",
    // Register page
    "WelcomeToFireAdmin": "Bienvenido a RodrySoler",
    "RepeatPassword": "Repetir Contraseña",
    "CreateAccount": "Crear Cuenta",
    // Sidebar
    "Dashboard": "Dashboard",
    "Pages": "Paginas",
    "List": "Lista",
    "Add": "Agregar",
    "Posts": "Publicaciones",
    "Categories": "Categorias",
    "Translations": "Traducciones",
    "Comments": "Comentarios",
    "Menus": "Menus",
    "Media": "Media",
    "Users": "Usuarios",
    // Navbar
    "AddPost": "Agregar Publicación",
    "Profile": "Perfil",
    "Settings": "Ajustes",
    "Logout": "Logout",
    // Dashboard
    "PostsByStatus": "Publicaciones Por Estado",
    // Pages: Add
    "AddNewPage": "Agregar Nueva Página",
    "PageTitle": "Titulo de Página",
    "PageSlug": "Slug",
    "PageLanguage": "Lenguaje",
    "PageBlock": "Bloque",
    "PageBlocks": "Bloques",
    "AddPage": "Agregar Página",
    "AddBlock": "Agregar Bloque",
    "BlockName": "Nombre",
    "BlockType": "Tipo",
    "BlockContent": "Contenido",
    "RemoveBlock": "Eliminar",
    "CollapseBlock": "Colapsar",
    "PageAdded": "Página Agregada!",
    "PageSlugAlreadyExists": "La Ruta de la Página ya existe!. Elige otra.",
    "AddProd": "Agregar Producto",
    "DeleteProd": "Eliminar Producto",
    // Pages: Block types
    "Text": "Text",
    "HTML": "HTML",
    "JSON": "JSON",
    // Pages: Edit
    "EditPage": "Editar Página",
    "PageSaved": "Página guardada!",
    // Pages: Translate
    "TranslatePage": "Traducir Página",
    // Pages: List
    "PageCreatedAt": "Fecha de Creación",
    "PageUpdatedAt": "Fecha de la última modificación ",
    "PageAuthor": "Autor",
    "DeletePage": "Eliminar Página",
    "ConfirmDeletePage": "¿Seguro desea borrar la página <b>${title}</b> ?",
    "PageDeleted": "La Página <b>${title}</b> fue borrada!",
    // Posts: Add
    "AddNewPost": "Agregar Nueva Publicación",
    "PostTitle": "Titulo de la Publicación",
    "PostContent": "Contenido de la Publicación",
    "Actions": "Acciones",
    "PostLanguage": "Lenguaje",
    "PostStatus": "Estado",
    "PostDate": "Fecha",
    "PostImage": "Imagen",
    "PostSlug": "Slug",
    "SaveDraft": "Guardar borrador",
    "Publish": "Publicar",
    "MoveToTrash": "Mover al Basurero",
    "PostAdded": "Publicación agregada!",
    "PostSlugAlreadyExists": "El slug de la publicación ya existe! Elige otro.",
    // Posts: Status
    "Draft": "Borrador",
    "Published": "Publicado",
    "Trash": "Eliminado",
    // Posts: List
    "PostCreatedAt": "Fecha de creacion",
    "PostUpdatedAt": "Última fecha de modificación",
    "PostAuthor": "Author",
    "Translate": "Traducir",
    "DeletePost": "Borrar Publicacion",
    "ConfirmDeletePost": "¿Seguro desea borrar la publicacion <b>${title}</b> ?",
    "PostDeleted": "La Publicacion <b>${title}</b> fue borrada!",
    // Posts: Edit
    "EditPost": "Editar Publicacion",
    "PostSaved": "Publicacion Guardada!",
    // Posts: Translate
    "TranslatePost": "Traducir Publicacion",
    // Languages
    "All": "Todos",
    "English": "Ingles",
    "French": "Frances",
    "Arabic": "Español",
    "Spanish": "Español",
    // Settings
    "General": "General",
    "Internationalization": "Internacionalización",
    "Language": "Lenguaje",
    "SidebarStyle": "Sidebar Estilos",
    "Expanded": "Expandido",
    "Collapsed": "Colapsado",
    "HeaderBar": "Header bar",
    "SaveChanges": "Guardar Cambios",
    "SettingsSaved" : "Ajustes guardados!",
    "SupportedLanguages" : "Lenguajes soportados",
    "Key": "Clave",
    "Remove": "Eliminar",
    "Cancel": "Cancelar",
    // Categories
    "NewCategory": "Nueva Categoria",
    "CategoryLabel": "Etiqueta",
    "CategorySlug": "Slug",
    "CategoryLanguage": "Lenguaje",
    "AddCategory": "Agegar Categoria",
    "CategoryAdded": "Categoria guardada!",
    "DeleteCategory": "Borrar Categoria",
    "ConfirmDeleteCategory": "¿Seguro desea eliminar la Categoria <b>${label}</b> ?",
    "CategoryDeleted": "Categoria <b>${label}</b> Eliminada!",
    "EditCategory": "Editar Categoria",
    "CategorySaved": "Categoria <b>${label}</b> guardada!",
    // Lists
    "Edit": "Editar",
    "Delete": "Borrar",
    "Save": "Guardar",
    // Users: Add
    "AddNewUser": "Agregar Nuevo Usuario",
    "AddUser": "Agegar Usuario",
    "NewUser": "Nuevo Usuario",
    "FirstName": "Nombre",
    "LastName": "Apellido",
    "Email": "Email",
    "BirthDate": "Fecha de nacimiento",
    "Role": "Rol",
    "Bio": "Bio",
    "BioPlaceholder": "Soy un ingeniero centrado en el diseño.",
    "ProfilePicture": "Foto de Perfil",
    "UploadImage": "Subir imagen",
    "UserAdded": "Usuario guardado!",
    // Users: Edit
    "EditUser": "Editar Usuario",
    "UserDetails": "Usuario Details",
    "UpdateUser": "Update Usuario",
    "UserUpdated": "Usuario successfully updated!",
    // Users: Profile
    "UserProfile": "Perfil de Usuario",
    "LatestPosts": "Ultimas Publicaciones",
    "FullList": "Lista Completa",
    // Users: List
    "UserName": "Nombre de Usuario",
    "CreatedAt": "Fecha de creacion",
    "UpdatedAt": "Fecha de la ultima moficacion",
    "CreatedBy": "Creado por",
    "DeleteUser": "Borrar Usuario",
    "ConfirmDeleteUser": "¿Seguro desea eliminar al Usuario <b>${name}</b> ?",
    "UserDeleted": "Usuario <b>${name}</b> Borrado!",
    // Users: Roles
    "Administrator": "Administrador",
    "Editor": "Editor",
    "Author": "Autor",
    "Contributor": "Contribuyente",
    "Guest": "Invitado",
    // Translations
    "NewTranslation": "Nueva Traducción",
    "TranslationKey": "Clave",
    "TranslationValue": "Valor",
    "TranslationLanguage": "Lenguaje",
    "AddTranslation": "Agregar Traducción",
    "TranslationAdded": "Traducción Agregada!",
    "DeleteTranslation": "Borrar Traducción",
    "ConfirmDeleteTranslation": "¿Seguro desea borrar la Traducción <b>${key}</b> ?",
    "TranslationDeleted": "Traducción <b>${key}</b> Eliminada!",
    "EditTranslation": "Editar Traducción",
    "TranslationSaved": "Traducción <b>${key}</b> Guardada!",
    "TranslationKeyAlreadyExists": "La Clave de Traducción <b>${key}</b> ya existe para el lenguaje <b>${lang}</b>.",
    "TranslationsTooltip": "Traducción can be used with <a href=\"http://www.ngx-translate.com/\" target=\"_blank\">ngx-translate</a> library, for any repetitive text (on header/footer or breadcrumb) that needs to be translated.",
    // Error messages
    "GuestsAreNotAllowedToPerformChanges": "Guest users are not allowed to perform changes.",
  };
  