import { Boton } from './boton.model';

export class Producto {
  titulo: string;
  foto: string;
  precio: string;
  moneda: string;
  boton: Boton;

  constructor(titulo: string, foto: string, precio: string, moneda: string, boton: Boton) {
    this.titulo = titulo;
    this.foto = foto;
    this.precio = precio;
    this.moneda = moneda;
    this.boton = boton;
  }
}
